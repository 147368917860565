import React, { useState,useEffect } from "react";
import uuid from 'react-uuid';
import { useStorage } from '../hooks/useStorage';
import {
    IonPage, IonContent, IonGrid, IonRow, IonCol,
    IonButton,IonList , IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonSelectOption,
    IonSelect, IonItem
  } from '@ionic/react';
  import {settingsOutline} from "ionicons/icons";
import sitelogo from "../assets/images/logo.jpg";
import './Slotbook.css';
import { useHistory } from 'react-router-dom';
import Footer from "./Footer";


var indents : any[] = [];
    for (var i = 1; i <= 57; i++) {
        indents.push(i);
    }
  const Slotbook: React.FC = () =>{
    const headers = {'Content-Type':'application/json',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS,GET'};

    const [showdiv,divVal]= useState(1);

    const history = useHistory();
    // const [token2,setToken]:any = useState();
    const [locations, setLocations]:any = useState([{
      name:"",
      zipcode:""
    }]);

    const [inputval, SetVal] = useState({
      date:"",
      timeslot:"",
      reason:"",
      token:"" ,
      location:""    
    });
    const [slots,updateSlots]:any = useState([]);
    const {todos,fetchTodo, updateTodo} = useStorage();

    // let todo2 = fetchTodo();
    // useEffect(() => {
    //   // setToken(state)
    //   let data = fetchTodo();
    //   const getkey = async() => {
    //     let data = await fetchTodo()  
    //     setToken(data.key);
    //   }
    //   getkey();
    //  },[todo2]);

     useEffect(() => {
      const requestOptions = {
        method: 'POST',
        headers: headers
      };
      fetch(process.env.REACT_APP_DOMAIN+'fetchlocations', requestOptions)
      .then(response => response.json())
      .then(data =>setLocations(data));
     },[]);

   const fillForm=  (obj)=>{
    
    const {name, value}=obj.target;
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({data:value })
    };


   fetch(process.env.REACT_APP_DOMAIN+'fetchbookings', requestOptions)
      .then(response => response.text())
      .then(data =>updateSlots(JSON.parse(data)));
    
    var val = fetchTodo();
    val.then(function(result) {
      SetVal(()=>{return{
        ...inputval,['token']:result.key,[name]:value
      }});
    });
     divVal(2);
   
   }

   const updateTime=(obj)=>{
    var timer_btn_clr = document.getElementsByClassName("timer_btn_clr");
    while (timer_btn_clr.length)
    timer_btn_clr[0].className = timer_btn_clr[0].className.replace(/\btimer_btn_clr\b/g, "");  

      const slot = obj.target.getAttribute("data-room-code");
      obj.target.parentNode.classList.add('timer_btn_clr');
        SetVal(()=>{return{
          ...inputval,['timeslot']:slot
        }});
   }
   
   const updatePurpose=(obj)=>{
    var purpose_btn_clr = document.getElementsByClassName("purpose_btn_clr");
    while (purpose_btn_clr.length)
    purpose_btn_clr[0].className = purpose_btn_clr[0].className.replace(/\bpurpose_btn_clr\b/g, "");  

      const reason = obj.target.innerHTML;
      obj.target.parentNode.classList.add('purpose_btn_clr');
      SetVal(()=>{return{
        ...inputval,['reason']:reason
      }});
     
   }

   const removeslots = ()=>{
        divVal(1);
   }

   const showreasons =()=>{
  
    if(inputval.date=='' || inputval.timeslot=='')
    {
      alert("Please select Date and Time to proceed");
      return false;
    }
    if(inputval.location=='')
    {
      alert("Please select location to proceed");
      return false;
    }
    
    divVal(3);
   }

   const updateData=()=>{

      const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({data:inputval }),
              
          };
         
      fetch(process.env.REACT_APP_DOMAIN+'bookings', requestOptions)
          .then(response => response.json())
          .then(data => updateTodo(inputval.token, data ,inputval.timeslot,inputval.date))
          .then(data=>window.location.href="/success") ;
   }
   const styles= {
    logoTop: {
      marginTop: 70,
    },
  }
  const formatDate=(val)=>{
      
    return val.getDate() + "-" + (val.getMonth()+1) + "-" + val.getFullYear() ;
  }
  let x = new Date('2100-01-05T08:00');
  let minDate: string = new Date().toISOString();
  // const cur_date = new Date();
  // const xy=formatDate(cur_date);
    // const listitem=
    return ( 
     
    <IonPage>
      <IonContent fullscreen>
        <IonGrid className="mains_grid">
            {/* <IonRow className="logo-top" >
                <IonCol size="3" className="ion-text-center"><img src ={sitelogo} alt="Site Logo" /></IonCol>
                <IonCol  size="9" className="hdr_nav">
                <IonList>
                  <ul>
                    <li onClick={()=>divVal(2)}><span className={(showdiv==1 || showdiv==2) ? "active":""} >Time <IonIcon icon={settingsOutline}></IonIcon></span></li>
                    <li onClick={()=>{if(inputval.date !=='' || inputval.timeslot !==''){return divVal(3)} else {alert("Please select Date and Time to proceed");}}} ><span className={showdiv==3 ? "active":""}  >Reason </span></li>
                    
                  </ul>
                  </IonList>
                </IonCol>
            </IonRow> */}
            <IonRow className="logo-top register_logo">
                <IonCol className=""><img onClick={()=>history.push("/dashboard")} src ={sitelogo} alt="Site Logo" /></IonCol>
                <IonCol className="hdr_icon"> <IonIcon onClick={()=>history.push("/account")} icon={settingsOutline}></IonIcon></IonCol>
            </IonRow>
            <IonGrid className="content_grid">        
              <IonRow className={showdiv <= 2 ? "main-content content1 ":"main-content dispnone "}>
                  <IonCol size="12" className="ion-text-center  select_txt"><h2>Please select slot date, time and location</h2></IonCol>
                  <IonCol size="12">
                    <IonItem >
                      <IonLabel position="floating">Location</IonLabel>
                      <IonSelect name="location" onIonChange={(obj)=>SetVal(()=>{return{...inputval,['location']:obj.target.value}})} value={inputval.location}>
                          {locations.map((value,index)=>{
                            return (
                              <IonSelectOption value={value.location_name}>{value.location_name}</IonSelectOption>
                            )
                          })}
                      </IonSelect>
                    </IonItem>  
                  </IonCol>
                  <IonCol className="main_calender">
                      <IonCol size="12" className="calender_lft">
                        {/* <IonCol className="calender_image"><img src ={sitelogo} alt="Site Logo" /></IonCol> 
                        <IonCol size="4" className="calender_txt">
                          <IonCol className="ion-text-left"><h2>Lorem Ipsum Dior</h2></IonCol>
                          <IonCol className="ion-text-left mt-2 text-sm italic font-secondary"><p>Lorem Ipsum test text</p></IonCol>
                        </IonCol> */}
                          
                      </IonCol>

                    <IonCol className="calender_rt">
                      <IonCol size="7" className="slots_list">
                          <IonList>
                              <IonDatetimeButton datetime="datetime"  id="time-slting"  className={showdiv===2 ? "short-cal" : "short-cal mob_screen"} onClick={removeslots}></IonDatetimeButton>
                            
                              <IonDatetime id="datetime"  name="date" presentation="date" min={minDate} className={showdiv===1 ? "short-cal short-cal-spc" : "short-cal mob_screen"} onIonChange={fillForm} ></IonDatetime>
                          </IonList>   
                      </IonCol>
                      <IonCol size="5" className={showdiv===2 ? "calender_last" : "calender_last mob_screen"}>
                        <IonCol className=""><h2>Available appointments</h2></IonCol>
                        <div className="mains-btn short-cal" >
                                <ul>
                                
                                  {indents.map((value,index)=>
                                    {
                                      x.setMinutes(x.getMinutes() + 15);
                                      var time1 = x.toString().split(' ')[4].slice(0, -3);
                                      
                                      // if(formatDate(inputval.date)==formatDate(cur_date))
                                      // {
                                      //   console.log('yes');
                                      // }
                                      if(slots.includes(value) == false)
                                      {
                                        return (<li key={index}><div className="timer_button  "><button onClick={updateTime} data-room-code={value} >{time1}</button></div></li>)
                                      }
                                    } 
                                    )
                                  } 
                                  
                                </ul>
                              </div>
                      </IonCol>
                    </IonCol>
                  </IonCol>
                                
                  <IonCol size="12">
                    {/* <IonButton expand="block" onClick={showreasons} >Save</IonButton> */}
                    <div className="custom_btn"><button onClick={showreasons}>Save</button></div>
                  </IonCol>
              </IonRow>
              <IonRow className={showdiv===3 ? "main-content  content1":"main-content  dispnone"}>
                  <IonCol size="12" className="ion-text-center select_txt">
                      <h2>For what purpose can we help you with the room ?</h2>
                  </IonCol>
                  <IonCol size="12" className="ion-text-center main_purpose">
                    <div className="purpose_btns  "><button onClick={updatePurpose} >Skin</button></div>
                    <div className="purpose_btns  "><button onClick={updatePurpose} >Eye</button></div>
                    <div className="purpose_btns  "><button onClick={updatePurpose} >General</button></div>
                  </IonCol>
                  <IonCol size="12" className="custom_btn">
                    <button onClick={updateData}>Save</button>
                  </IonCol>
              </IonRow>
              <IonRow>
                <Footer />
              </IonRow>
            </IonGrid>
        </IonGrid>
      </IonContent> 
    </IonPage>
    )
  }
  
  export default Slotbook; 