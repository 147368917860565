import React, { useState,useEffect } from "react";
import { useHistory , useLocation} from 'react-router-dom';
import { useStorage } from '../hooks/useStorage';

import {
    IonPage,  IonContent, IonGrid, IonRow, IonCol,
    IonLabel,
    IonInput,
    IonButton,
    IonItem, IonList, IonSelect, IonSelectOption 
  } from '@ionic/react';
import {chevronForwardOutline} from "ionicons/icons";
import sitelogo from '../assets/images/logo.jpg';
import './Register.css';

// interface stateType {
//   state: { key: string }
// }
  const Account: React.FC = () =>{

    // const { state } = useLocation<stateType>();
    const headers = {'Content-Type':'application/json',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS,GET'};
    const history = useHistory();
    
    const [token,setToken]:any = useState();
    const [genderflag, setFlag] =useState(0);
    // const [key, setkey]:any = useState("");
    const [inputval, SetVal] = useState({
      dob:"",
      nationality:"",
      gender:"",
      token:""
    });

    const {todos,addTodo,fetchTodo} = useStorage();
    let todo = fetchTodo();
    useEffect(() => {
      // setToken(state)
      let data = fetchTodo();
      const getkey = async() => {
        let data = await fetchTodo()  
        setToken(data.key);
      }
      getkey();
     },[todo]);

    useEffect(()=>{
      if(typeof token !== 'undefined')
      {
        
        fetch(process.env.REACT_APP_DOMAIN+'user/fetch', { 
          method: 'POST',
          headers: headers,
          body:JSON.stringify({data:token }) })
        .then(response => response.json()) 
        .then(data=>SetVal((prev)=>{return{...prev,['nationality']:data[0].nationality,['dob']:data[0].dob,gender:data[0].gender,['token']:token.substring(token.length - 6)}}))
      }
      
    },[token]);  
  
    const updateval=(obj)=>{
     
        const {name, value}= obj.target;
        SetVal(()=>{return{
          ...inputval,[name]:value
        }});
    };
    const updateval2=(obj)=>{
     
      const {name, value}= obj.target;
      if(genderflag==1)
      {
        SetVal(()=>{return{
          ...inputval,[name]:value
        }});
      }
     
  };

    const delaccount = async ()=>{
     
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ acode:token})
    };
      indexedDB.deleteDatabase('tokendb');
      fetch(process.env.REACT_APP_DOMAIN+'deleteacct', requestOptions)
      .then(response => response.text())
      .then(data => window.location.href="/index");
    } 
                                           
    const saveData =()=>{
      
        if(inputval.gender == '')
        {
          alert("Please enter the Gender");
          return false;
        }
        else if(inputval.dob == '')
        {
          alert("Please enter the Birth date");
          return false;
        }
        else if(inputval.nationality == '')
        {
          alert("Please enter the Country");
          return false;
        }
        setFlag(0);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ formdata: inputval,token:token })          
      };
      
      fetch(process.env.REACT_APP_DOMAIN+'user/update', requestOptions)
              .then(response => response.text())
              .then(data => history.push("/dashboard"));
      
    };
    return (
    <IonPage >
      <IonContent fullscreen>
        <IonGrid className="mains_grid">
            <IonRow className="logo-top register_logo">
                <IonCol className="ion-text-center"><img onClick={()=>history.push("/dashboard")} src ={sitelogo} alt="Site Logo" /></IonCol>
            </IonRow>

            <IonRow className="main-content content1">
                <IonCol size="12">
                    <IonList className="gender_form gndr_frm">
                      <IonItem > 
                        <IonLabel position="floating">Gender</IonLabel>
                        <IonSelect name="gender" onClick={()=>setFlag(1)} onIonChange={updateval2} value={inputval.gender}>
                            <IonSelectOption value="Male">Male</IonSelectOption>
                            <IonSelectOption value="Female">Female</IonSelectOption>
                        </IonSelect>  
                      </IonItem > 
                      <IonItem >  
                         <IonLabel position="floating">Birth Year</IonLabel>
                        <IonInput type="date" name="dob" placeholder="Birth Year" onIonInput={updateval} value={inputval.dob}> </IonInput>
                      </IonItem>          
                      <IonItem>
                        <IonLabel position="floating"> Nationality </IonLabel>
                        <IonInput type="text" name="nationality" placeholder="Nationality" onIonInput={updateval} value={inputval.nationality}> </IonInput>
                      </IonItem>  
                      <div className="custom_btn"><button  onClick={saveData}>Save</button></div>
                   
                    </IonList>   
                </IonCol>
               
                <IonCol size="12">
                <IonList className="gender_form gndr_frm">
                  <IonItem >
                    <IonInput type="text" name="token" value={inputval.token}> </IonInput>
                  </IonItem >
                  <div className="custom_btn"><button  onClick={()=>delaccount()}>Delete Account</button></div>
                </IonList>
                  
                </IonCol>
            </IonRow>

        </IonGrid>
      </IonContent> 
    </IonPage>
    )
  }
  export default Account;