import React, { useState,useEffect } from "react";
import {
    IonCol,
    
  } from '@ionic/react';

  const Footer: React.FC = () =>{
    
    return ( 
            <IonCol className="footer_data" size="12">
                <a href="#">Privacy Policy</a> <a href="#">Imprint</a> <a href="#">Terms and Conditions</a>
            </IonCol>
    )
  }
  
  export default Footer;