import React, { useState,useEffect } from "react";
import { useStorage } from '../hooks/useStorage';
import { useHistory } from 'react-router-dom';


import uuid from 'react-uuid';
import {
    IonPage,  IonContent, IonGrid, IonRow, IonCol,
    IonLabel,
    IonInput,
    IonButton,
    IonItem, IonList, IonSelect, IonSelectOption 
  } from '@ionic/react';
import sitelogo from '../assets/images/logo.jpg';
import Footer from "./Footer";
import './Register.css';
  const RegisterForm: React.FC = () =>{
    const history = useHistory();
    const headers = {'Content-Type':'application/json',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS,GET'};
    
    const [showflag,updateFlag]=useState(0);
    const {todos,addTodo} = useStorage();
    const [inputval, SetVal] = useState({
      gender:"",
      dob:"",
      nationality:""      
    });
    
    
    const updateval=(obj)=>{
        const {name, value}= obj.target;
        SetVal(()=>{return{
          ...inputval,[name]:value
        }});
    };
    // const [tokenVal,generateToken]=useState(()=>"");
    const createTodo = async ()=>{
      // if(tokenVal=='')
      // {
      //   alert("Please generate token to proceed");
      //   return false;
      // }
      var tokenval = uuid();
      
      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ formdata: inputval,token:tokenval })
    };
    
          fetch(process.env.REACT_APP_DOMAIN+'user/register', requestOptions)
              .then(response => response.text())
              .then(data =>  addTodo(tokenval))
              .then(data => updateFlag(1))
              .then(data => history.push("/slotbook"));
    } 

    

    function transit_open(){
      var element = document.getElementsByClassName("logo-top");
      element[0].classList.add("transition-logo");
      setTimeout(() => {
        
        updateFlag(1);
      }, 300);
      
    }
    useEffect(() => {setTimeout(transit_open, 500)}, []);

    const saveData =()=>{
      
        if(inputval.gender == '')
        {
          alert("Please enter the Gender");
          return false;
        }
        else if(inputval.dob == '')
        {
          alert("Please enter the Birth date");
          return false;
        }
        else if(inputval.nationality == '')
        {
          alert("Please enter the Country");
          return false;
        }
        updateFlag(2);
      
    };
    return (
    <IonPage >
      <IonContent fullscreen>
        <IonGrid className="mains_grid">
            <IonRow className="logo-top register_logo">
                <IonCol className="ion-text-center"><img src ={sitelogo} alt="Site Logo" /></IonCol>
        
            </IonRow>

            <IonRow className={showflag==1 ? "main-content content1 reg_blk": "main-content dispnone"}>
                <IonCol size="12">
                  <h2>Sign Up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </IonCol>  
                <IonCol size="12">
                    <IonList className="gender_form">
                      <IonItem > 
                        <IonLabel position="floating">Gender</IonLabel>
                        <IonSelect name="gender" onIonChange={updateval} value={inputval.gender}>
                            <IonSelectOption value="Male">Male</IonSelectOption>
                            <IonSelectOption value="Female">Female</IonSelectOption>
                        </IonSelect>  
                      </IonItem > 
                      <IonItem >  
                         <IonLabel position="floating">Birth Year</IonLabel>
                        <IonInput type="date" name="dob" placeholder="Birth Year" onIonInput={updateval} value={inputval.dob}> </IonInput>
                      </IonItem>  
                                        
                      <IonItem>
                        <IonLabel position="floating"> Nationality </IonLabel>
                        <IonInput type="text" name="nationality" placeholder="Nationality" onIonInput={updateval} value={inputval.nationality}> </IonInput>
                      </IonItem>  
                    </IonList>   
                </IonCol>
                <IonCol size="12" className="custom_btn">
                  <button  onClick={saveData}>Save</button>
                  
                </IonCol>
            </IonRow>

            <IonRow className={showflag==2? "main-content content1 main_tokens": "main-content dispnone"}> 
                <IonCol size="12">
                  <button  className="token-generator" onClick={()=>createTodo()} >Start Now <br/><span>Generate Personal Token</span></button>
                </IonCol>
                {/* <IonCol size="12" className="regster_input">
                  <IonItem>
                    <IonInput type="text" name="token-input" value={tokenVal} readonly> </IonInput>
                  </IonItem> 
                </IonCol> */}
                {/* <IonCol size="12" className="bottom_save">
                  <button onClick={()=>createTodo()}>Save</button>
                </IonCol> */}
            </IonRow>
            <IonRow>
              <IonCol className="prefooter" size="12">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br/>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                     Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </IonCol>
              <IonCol size="12">
                <Footer />
              </IonCol>
            </IonRow>
        </IonGrid>
      </IonContent> 
    </IonPage>
    )
  }
  export default RegisterForm;