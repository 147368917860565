import { Redirect, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Storage } from '@ionic/storage';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import RegisterForm from './pages/Register';
import Slotbook from './pages/SlotBook';
import Success from './pages/Success';
import SlotDashboard from './pages/SlotDashboard';
import Account from './pages/Account';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { useEffect, useState } from 'react';

/* Theme variables */
// import './theme/variables.css';

setupIonicReact();


const App: React.FC = (acode:any) => {

  const TODOS_KEY = 'booking_key';
  const [key,updateKey]= useState("");
  
  const initStorage= async (val) =>{
    const newStore = new Storage({
        name:'tokendb'
    })
    const store = await newStore.create();

    const storedTodos = await store.get(TODOS_KEY) || [];
     updateKey(await storedTodos["key"]);
    if(val==1)
    {
      return storedTodos["key"] !== undefined;
    }
    else if(val==2)
    {
      return storedTodos["key"];
    }
    
}

  useEffect(()=>{
    var res= initStorage(1);
    res.then(function(result) {
      if(result==true && window.location.pathname == '/index')
      {
        window.location.href="/slotbook";
      }
      else if(result==false && window.location.pathname !== '/index')
      {
        window.location.href="/index";
      }
    });
  },[]); 

  return(
  <IonApp>
    <IonReactRouter>
  
        <IonRouterOutlet>
          <Route exact path="/index">
            <RegisterForm /> 
          </Route>
          <Route exact path="/slotbook">
            <Slotbook /> 
          </Route>
          <Route exact path="/dashboard">
            <SlotDashboard /> 
          </Route>
          <Route exact path="/account">
            <Account /> 
          </Route>
          <Route exact path="/success">
            <Success /> 
          </Route>

          <Route exact path="/">
            <Redirect to="/index" />
          </Route>
        </IonRouterOutlet>

    </IonReactRouter>
  </IonApp>
  )
}

  

export default App;
