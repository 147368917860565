import React, { useState,useEffect } from "react";
import uuid from 'react-uuid';
import { useStorage } from '../hooks/useStorage';
import { Storage } from '@ionic/storage';
import {
    IonPage, IonHeader,IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
    IonItem, IonList, IonSelect, IonSelectOption , IonDatetime, IonDatetimeButton, IonModal
  } from '@ionic/react';
import sitelogo from '../assets/images/logo.jpg';
import './Slotbook.css';
import { response } from "express";
import Slotbook from "./SlotBook";
import { useHistory } from 'react-router-dom';

const element = document.getElementsByClassName("short-cal");
const element2 = document.getElementsByClassName("main-content");
const element3 = document.querySelector('#time-slting');


var indents : any[] = [];
    for (var i = 1; i <= 57; i++) {
        indents.push(i);
    }
  const Success: React.FC = () =>{
    const TODOS_KEY = 'booking_key';
    const history = useHistory();
    let x = new Date('2100-01-05T08:00');

    const gettime=(slot)=>{
      x.setMinutes(x.getMinutes() + (15 * slot));
      var time1 = x.toString().split(' ')[4].slice(0, -3);
      return time1;
    }
    const formatDate=(date)=>{
      
      return date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear() ;
    }
    
   const [slotdata, setSlot] = useState({
    date:"",
    timeslot:"",
    reason:"",
    token:""     
  });
   const initStorage= async () =>{
    const newStore = new Storage({
        name:'tokendb'
    })
    const store = await newStore.create();
    // setStore(store);

    const storedTodos = await store.get(TODOS_KEY) || [];
    // setTodos(storedTodos);
    var d = new Date(storedTodos["bdate"]);
    var formattedDate = formatDate(d);
    setSlot(()=>{
      return { 
        ...slotdata,['token']:storedTodos["key"],['timeslot']:storedTodos["timeslot"],['date']:formattedDate
      }
    });
}

   const styles= {
    logoTop: {
      marginTop: 70,
    },
  }
  const headers = {'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS,GET'}
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body:""
};
useEffect(()=>{
  initStorage();

  },[]);

    // const listitem=
    return ( 
     
    <IonPage >
      <IonContent fullscreen>
        <IonGrid className="mains_grid">
            <IonRow className="logo-top" >
                <IonCol className="ion-text-center"><img onClick={()=>history.push("/dashboard")} src ={sitelogo} alt="Site Logo" /></IonCol>
            </IonRow>
            <IonRow className="main-content content1 ion-margin-top">
              <IonCol size="12" className="personal_msg"><p>Your appointment with token number {slotdata.token} has been requested on {slotdata.date} at {gettime(slotdata.timeslot)}</p></IonCol>
              
            </IonRow>
           
        </IonGrid>
      </IonContent> 
    </IonPage>
    )
  }
  
  export default Success;