import { useEffect, useState } from "react";
import { Storage } from '@ionic/storage';
// import { userInfo } from "os";

const TODOS_KEY = 'booking_key'

export interface TodoItem{
    key: String;
    created: number;
}

export function useStorage(){
    const [store,setStore] = useState<Storage>();
    const [todos, setTodos] = useState<TodoItem>();

    useEffect(()=>{
        const initStorage= async () =>{
            const newStore = new Storage({
                name:'tokendb'
            })
            const store = await newStore.create();
            setStore(store);

            const storedTodos = await store.get(TODOS_KEY) || [];
            setTodos(storedTodos);
        }
        initStorage();
    },[]);
    const addTodo = async (key:string)=>{
        const newTodo ={
            key,
            created: new Date().getTime(),
        }

        setTodos(newTodo);
        store?.set(TODOS_KEY, newTodo);
        

    }
    const updateTodo = async (key:string,id:string,timeslot:string,bdate:string)=>{
        const newTodo ={
            id,
            key,
            timeslot,
            bdate,
            created: new Date().getTime()
        }
   
        setTodos(newTodo);
        store?.set(TODOS_KEY, newTodo);
        
    }
    const fetchTodo = async()=>{
       return store?.get(TODOS_KEY);
    }
  
    return { todos,addTodo,fetchTodo,updateTodo }
}