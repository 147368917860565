import React, { useState,useEffect } from "react";
import { useStorage } from '../hooks/useStorage';
import { useHistory } from 'react-router-dom';
import {settingsOutline} from "ionicons/icons";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
    IonPage,  IonContent, IonGrid, IonRow, IonCol,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,   IonItem, IonList, IonSelect, IonSelectOption 
  } from '@ionic/react';
import sitelogo from '../assets/images/logo.jpg';
import './Register.css';

  const SlotDashboard: React.FC = () =>{
    const history = useHistory();
    const [token2,setToken]:any = useState();
    const {todos,fetchTodo, updateTodo} = useStorage();
    const [bookings,setBookings]:any =useState([{
      date:"",
      timeslot:"",
      location:"",
      reason:"",
      request:""
    }]);
    const headers = {'Content-Type':'application/json',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS,GET'};

    let todo2 = fetchTodo();
    useEffect(() => {
      // setToken(state)
      let data = fetchTodo();
      const getkey = async() => {
        let data = await fetchTodo()  
        setToken(data.key);
      }
      getkey();
     },[todo2]);

     useEffect(()=>{
      if(typeof token2 !== 'undefined')
      {
        var today = new Date();
        var dd = String(today.getDate());
        var mm = String(today.getMonth() + 1); 
        var yyyy = today.getFullYear();
        var todayy =  dd+ '-' +mm + '-' + yyyy ;
        
        fetch(process.env.REACT_APP_DOMAIN+'fetchbookings/nearestbookings', { 
          method: 'POST',
          headers: headers,
          body:JSON.stringify({token:token2 ,cur_date:todayy}) })
        .then(response => response.json()) 
        .then(data=>setBookings(data)); 
      }
      
    },[token2]); 

    const cancelAppointment=(id)=>{
      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({id:id })
      };
      var myNewArray = bookings.filter(function(item){ return item['id'] != id });
      
      confirmAlert({
        title: 'Cancel Appointment',
        message: 'Please confirm if you want to cancel the appointment',
        buttons: [
          {
            label: 'Yes',
            onClick: () => fetch(process.env.REACT_APP_DOMAIN+'bookings/cancel',requestOptions)
            .then(response=>response.text())
            .then(data=>setBookings(myNewArray))
          },
          {
            label: 'No',
            onClick: () => {return false}
          }
        ]
      });
         
    }
    var element2 = document.getElementsByClassName("main-content");
    function transit_open(){
      var element = document.getElementsByClassName("logo-top");
      element[0].classList.add("transition-logo");
      setTimeout(() => {
        element2[0].classList.add("content1");
      }, 300);
      
    }
    useEffect(() => {setTimeout(transit_open, 500)}, []);
  
    return (
    <IonPage >
      <IonContent fullscreen>
        <IonGrid className="mains_grid">
            <IonRow className="logo-top register_logo">
                <IonCol className=""><img src ={sitelogo} alt="Site Logo" /></IonCol>
                <IonCol className="hdr_icon"> <IonIcon onClick={()=>history.push("/account")} icon={settingsOutline}></IonIcon></IonCol>
            </IonRow>
            <IonRow className="main-content content1"> 
            
                <IonCol size="12" className="rgster_txt">
                  <h2>Appointment:</h2>
                </IonCol>
                <IonCol size="12">
                  <IonButton expand="block" className="big-btn" onClick={()=>{history.push("/slotbook")}} fill="outline">Book Now</IonButton>
                </IonCol>
                <IonCol size="12" className="upcmg_txt">
                  <h2>Upcoming Appointments:</h2>
                </IonCol>
                {bookings.map((data)=>{
                  let x = new Date('2100-01-05T08:00');
                  x.setMinutes(x.getMinutes() + (data.timeslot * 15));
                  var time1 = x.toString().split(' ')[4].slice(0, -3);
                  if(data.request=='0')
                  {
                    return (
                      <>
                        <IonList className="book_list">
                        <ul>
                          <li>{data.date} - {time1} Uhr</li>
                          <li>Locationname</li>
                          <li>Task:{data.reason} <span>Pending</span></li>
                        </ul>
                        </IonList>
                        <IonCol size="12" className="bottom_save appoint_button">
                      
                        <button onClick={()=>cancelAppointment(data.id)}>Cancel Appointment</button>
                      </IonCol>
                      </>
                    )
                  }
                  else if(data.request=='1')
                  {
                    return (
                      <> 
                      <IonList className="book_list book_list2">
                        <ul>
                          <li>{data.date} - {time1} Uhr</li>
                          <li>Locationname</li>
                          <li>Task:{data.reason} <span className="approve">Approved</span></li>
                        </ul>
                        </IonList>
                        <IonCol size="12" className="bottom_save cancel_btn">
                          <button onClick={()=>cancelAppointment(data.id)} className="cncel_btn" >Cancel</button>
                          <button className="acess_btn" >Access</button>
                        </IonCol>
                        
                      </>
                    )
                  }
                  
                })} 
                
            </IonRow>
        </IonGrid>
      </IonContent> 
    </IonPage>
    )
  }
  export default SlotDashboard;